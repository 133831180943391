import React from 'react';
import PropTypes from 'prop-types';
import { Notification } from '@lmig/lmds-react';

const ExpiredEntityBanner = ({
  entityType,
}) => (
  <div data-theme="evolved">
    <Notification inline isDismissible highlightType="caution" id="expired-entity">
      {`The insurance ${entityType} you're looking for is no longer available. Please search again.`}
    </Notification>
  </div>);

ExpiredEntityBanner.propTypes = {
  entityType: PropTypes.oneOf(['agent', 'office']).isRequired,
};

export default ExpiredEntityBanner;
