import React, { useEffect, useState, useContext } from "react";
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import ComponentMapRender from "@lmig/dotcom-aspect-components/Utility/ComponentMapRender";
import { ContextApp } from '@lmig/dotcom-aspect-components/ContextApp';
import Heading from "@lmig/dotcom-aspect-components/Utility/Heading";
import RichText from "@lmig/dotcom-aspect-comparion-components/RichText";
import withPreprocessedContent from "@lmig/dotcom-aspect-comparion-components/Utility/withPreprocessedContent";
import Head from "@lmig/dotcom-aspect-comparion-components/Head";
import Layout from "@lmig/dotcom-aspect-comparion-components/Layout";
import Hero from "@lmig/dotcom-aspect-comparion-components/Hero";
import WhyShouldI from "@lmig/dotcom-aspect-comparion-components/WhyShouldI";
import InsuranceProducts from "@lmig/dotcom-aspect-comparion-components/InsuranceProducts";
import WrapperSizeable from "@lmig/dotcom-aspect-comparion-components/Utility/WrapperSizeable";
import ResourceFeatured from "@lmig/dotcom-aspect-comparion-components/ResourceFeatured";
import { getServerSideProps as callGetServerSideProps } from "@lmig/dotcom-aspect-comparion-helpers/staticPageController";
import { emsGraphQlQuery } from "@lmig/dotcom-aspect-comparion-helpers/emsGraphQlQuery";
import { findResourceAuthors } from '@lmig/dotcom-aspect-comparion-helpers/contentHelpers';
import { filterUndefined } from "@lmig/dotcom-aspect-comparion-helpers/filterUndefined";
import { formatAgent } from "@lmig/dotcom-aspect-comparion-helpers/formatEntity";
import fetchRetry from "@lmig/dotcom-aspect-comparion-helpers/fetchRetry";
import ReviewsByLocation from "@lmig/dotcom-aspect-comparion-components/Homepage/ReviewsByLocation";
import AgentsNearbyMap from "@lmig/dotcom-aspect-comparion-components/Homepage/AgentsNearbyMap";
import FindAgentSearchBar from "@lmig/dotcom-aspect-comparion-components/Search/FindAgentSearchBar";
import InsuranceProductsList from "@lmig/dotcom-aspect-comparion-components/InsuranceProductsList";

const InsurancePartners = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/InsurancePartners"));

const Homepage = withPreprocessedContent(({ content: { mainContent = [], meta = {} }, agent }) => {
  const [nearbyOffices, setNearbyOffices] = useState([]);
  const [nearbyAgents, setNearbyAgents] = useState([]);
  const { location } = useContext(ContextApp);
  const { serverRuntimeConfig: { baseUrl } } = getConfig();
  const explicitBaseUrl = baseUrl === undefined ? '' : baseUrl;

  useEffect(() => {
    const performSearch = async (searchParams) => {
      const responseOffices = await fetchRetry(`${explicitBaseUrl}/api/v1/officeSearch?${searchParams}`);
      const responseAgents = await fetchRetry(`${explicitBaseUrl}/api/v1/agentSearch?${searchParams}`);
      const searchResults = await responseOffices.json();
      const searchResultsAgents = await responseAgents.json();

      setNearbyOffices(searchResults?.data);
      setNearbyAgents(searchResultsAgents?.data);
    };

    if (location?.city && location?.state) {
      performSearch(`address=${location.city},${location.state}&searchRadius=5&reviews=true`).catch(console.error);
    }
  }, [location, explicitBaseUrl]);

  const componentMap = {
    insurancePartners: ({ ...rest }) => <InsurancePartners {...rest} />,
    heading: Heading,
    insuranceProducts: InsuranceProducts,
    richText: RichText,
    whyShouldI: WhyShouldI,
    wrapperSizeable: WrapperSizeable,
    resourceFeatured: (rest) => <ResourceFeatured agentImageAlt={agent?.image?.alt} agentImageSrc={agent?.image?.src}{...rest} />,
    reviewsByLocation: (rest) => <ReviewsByLocation entity={nearbyOffices} {...rest} />,
    agentsNearbyMap: (rest) => <AgentsNearbyMap entity={nearbyAgents} city={location?.city} state={location?.state} {...rest} />,
    hero: Hero,
    findAgentSearchBar: FindAgentSearchBar,
    insuranceProductsList: InsuranceProductsList,
  };

  return (
    <>
      <Head {...meta} />
      <Layout>
        <ComponentMapRender componentMap={componentMap} content={mainContent} />
      </Layout>
    </>
  );
});

const getServerSideProps = async (context) => {
  const resultProps = await callGetServerSideProps(context);
  const authorAgent = findResourceAuthors({ components: resultProps.props.content.mainContent, name: "resourceFeatured" });

  const { data: { homepageFeaturedAuthor } } = await emsGraphQlQuery({
    cacheKeyPrefix: `homepage-featured-author-alias-${authorAgent.join(",")}`,
    params: { query: `query{homepageFeaturedAuthor:AgentOne(filter:{type:FIELD,alias:"${authorAgent[0]}"}){image{src alt}}}` }
  });

  const formattedAgent = filterUndefined(formatAgent(homepageFeaturedAuthor));

  resultProps.props.agent = formattedAgent;

  return resultProps;
};

export { getServerSideProps };

export default Homepage;