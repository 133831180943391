/* eslint-disable react/no-unknown-property */
import React from 'react';
import { oneOfType, node, arrayOf, string, object, number } from 'prop-types';
import css from 'styled-jsx/css';
import breakpoints from '@lmig/lmds-tokens/dist/json/core/breakpoint.json';

const { lmig: { breakpoint: {
  sm: { min: bpSmMin },
  md: { min: bpMdMin },
  lg: { min: bpLgMin },
  xl: { min: bpXlMin },
} } } = breakpoints;

const getBackgroundStyles = (color, colorHeight) => {
  const colorMap = {
    'atmosphericGray': '#f5f5f5',
    'coolWhite': '#f5f5f5',
    'lightLibertyTeal': '#f2fcfc',
    'lightLibertyYellow': 'rgba(255, 208, 0, 0.1)',
    'libertyBlue': '#1a1446',
    'libertyYellow': '#ffd000',
    'white': '#ffffff',
  };

  const colorWithHeight = colorValue => colorHeight ?
    `background: linear-gradient(to bottom, ${colorValue} ${colorHeight}, #ffffff ${colorHeight})` :
    `background-color: ${colorValue}`;

  if (color in colorMap) {
    return css.resolve`
      div {
        ${colorWithHeight(colorMap[color])}
      }
    `;
  }

  if (['#', 'rgb(', 'rgba('].some(str => color.startsWith(str))) {
    return css.resolve`
      div {
        ${colorWithHeight(color)}
      }
    `;
  }

  if (['linear-', 'radial-'].some(str => color.startsWith(str))) {
    return css.resolve`
      div {
        background: ${color};
      }
    `;
  }

  return { className: '', styles: '' };
};

const getSizingStyles = (marginProp, paddingProp) => {
  let margin;
  let padding;
  const zeroEdges = {
    base: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0,
  };

  if (marginProp === 0) {
    margin = zeroEdges;
    margin.xl = '0 auto';
  } else if (typeof marginProp === 'string') {
    margin = {
      base: `${marginProp} 0`,
      sm: `${marginProp} 0`,
      md: `${marginProp} 0`,
      lg: `${marginProp} 0`,
      xl: `${marginProp} auto`,
    };
  } else {
    margin = marginProp;

    if (!('base' in margin)) {
      margin.base = '2.5rem auto';
    }

    if (!('xl' in margin)) {
      margin.xl = '2.5rem auto';
    }
  }

  if (paddingProp === 0) {
    padding = zeroEdges;
  } else if (typeof paddingProp === 'string') {
    padding = {
      base: `${paddingProp} 1rem`,
      sm: `${paddingProp} 1rem`,
      md: `${paddingProp} 4rem`,
      lg: `${paddingProp} 4rem`,
      xl: `${paddingProp} 4rem`,
    };
  } else {
    padding = paddingProp;

    if (!('base' in padding)) {
      padding.base = '0 1rem';
    }

    if (!('md' in padding)) {
      padding.md = '0 4rem';
    }
  }

  return { padding, margin };
};

// eslint-disable-next-line complexity
const getInnerWrapper = (paddingStyles, marginStyles, maxWidth) => css.resolve`
    .innerWrapper {
      position: relative;
      max-width: ${maxWidth};
      ${'base' in paddingStyles ? `padding: ${paddingStyles.base};` : ''}
      ${'base' in marginStyles ? `margin: ${marginStyles.base};` : ''}

      ${('sm' in paddingStyles || 'sm' in marginStyles)
    ? `@media screen and (min-width: ${bpSmMin}){
      ${'sm' in marginStyles ? `margin: ${marginStyles.sm};` : ''}
      ${'sm' in paddingStyles ? `padding: ${paddingStyles.sm};` : ''}}`
    : ''}

      ${('md' in paddingStyles || 'md' in marginStyles)
    ? `@media screen and (min-width: ${bpMdMin}){
      ${'md' in marginStyles ? `margin: ${marginStyles.md};` : ''}
      ${'md' in paddingStyles ? `padding: ${paddingStyles.md};` : ''}}`
    : ''}

      ${('lg' in paddingStyles || 'lg' in marginStyles)
    ? `@media screen and (min-width: ${bpLgMin}){
      ${'lg' in marginStyles ? `margin: ${marginStyles.lg};` : ''}
      ${'lg' in paddingStyles ? `padding: ${paddingStyles.lg};` : ''}}`
    : ''}

      ${('xl' in paddingStyles || 'xl' in marginStyles)
    ? `@media screen and (min-width: ${bpXlMin}){
      ${'xl' in marginStyles ? `margin: ${marginStyles.xl};` : ''}
      ${'xl' in paddingStyles ? `padding: ${paddingStyles.xl};` : ''}}`
    : ''}
  }`;

// eslint-disable-next-line complexity
const WrapperSizeable = ({
  margin = {},
  padding = {},
  maxWidth = '80rem',
  backgroundColor = 'white',
  backgroundHeight,
  children = [],
  id
}) => {

  const { className: backgroundClass, styles: backgroundStyles } = getBackgroundStyles(backgroundColor, backgroundHeight);
  const { padding: paddingStyles, margin: marginStyles } = getSizingStyles(margin, padding);
  const { className: innerWrapper, styles: innerWrapperStyles } = getInnerWrapper(paddingStyles, marginStyles, maxWidth);


  return (
    <div className={`wrapperSizeable ${backgroundClass}`} id={id}>
      <div className={`innerWrapper ${innerWrapper}`}>
        {children}
      </div>
      {backgroundStyles}
      {innerWrapperStyles}
    </div>
  );
};

WrapperSizeable.propTypes = {
  /* Margin and padding are valid css values, optionally by breakpoint. Hence:
  0, 'css', or {base: 'css', sm: 'css', md: 'css', lg: 'css', xl: 'css'}  */
  margin: oneOfType([number, string, object]),
  padding: oneOfType([number, string, object]),
  maxWidth: string,
  backgroundColor: string,
  /* any interpretable css distance (px, rem,  %, etc) */
  backgroundHeight: string,
  children: oneOfType([arrayOf(node), node]),
  id: string,
};

export default WrapperSizeable;
